import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { withRouter } from "react-router-dom";
import { useAuth } from "../../context/auth.context";
import AuthApi from "../../api/auth";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default withRouter(function LoginPage({ history }) {
  const classes = useStyles();
  const { setUser } = useAuth();
  const { user } = useAuth();

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = useState("test@appseed.us");
  const [password, setPassword] = useState("pass");
  const [error, setError] = useState(undefined);
  const [buttonText, setButtonText] = useState("Let's go");

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/admin/dashboard");
    }

    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    try {
      let response = await AuthApi.Login({
        email,
        password,
      });
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      setButtonText("Logging in");
      return setProfile(response);
    } catch (err) {
      console.log(err);
      setButtonText("Let's go");
      if (err.response) {
        return setError(err.response.data.msg);
      }
      return setError("There has been an error.");
    }
  };

  const setProfile = (response) => {
    let user = { ...response.data.user };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/admin/dashboard");
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>
                  {"React Node JS Material PRO"}
                </h4>
                <div className={classes.socialLine}>
                  <p style={{ textAlign: "center" }}>
                    {"Default login: test@appseed.us / pass"}
                  </p>
                </div>
              </CardHeader>
              <CardBody>
                {user && user.token ? (
                  <div>
                    <h3 style={{ textAlign: "center" }}>
                      You are already logged in
                    </h3>
                  </div>
                ) : (
                  <>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: `${email}`,
                        onChange: (event) => {
                          setEmail(event.target.value);
                          setError(undefined);
                        },
                        onKeyPress: (event) => {
                          event.key === "Enter" && event.preventDefault();
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        defaultValue: `${password}`,
                        onChange: (event) => {
                          setPassword(event.target.value);
                          setError(undefined);
                        },
                        onKeyPress: () => {
                          event.key === "Enter" && event.preventDefault();
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "1em",
                        color: "red",
                        textAlign: "center",
                        fontWeight: 400,
                        height: "25px",
                        transition: ".2s all",
                        opacity: error !== undefined ? 1 : 0,
                      }}
                    >
                      {error}
                    </h5>
                  </>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  disabled={error ? true : false}
                  type="submit"
                  onClick={login}
                  color="rose"
                  simple
                  size="lg"
                  block
                >
                  {buttonText}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
});
