import React from "react";

import { AuthProvider } from "./context/auth.context";
import { Routes } from "./Router";

const App = () => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <AuthProvider userData={user}>
      <Routes />
    </AuthProvider>
  );
};

export default App;
