import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "./context/auth.context";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  const classes = useStyles();
  let { user } = useAuth();

  if (!user || !user.token || user.token === "") {
    return (
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="You must be logged in!"
        onConfirm={() => history.push("/auth/login")}
        onCancel={() => history.push("/auth/login")}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      />
    );
  }

  return <Route {...rest} />;
};
