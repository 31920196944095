import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "./layouts/Auth.js";
import RtlLayout from "./layouts/RTL.js";
import AdminLayout from "./layouts/Admin.js";

import { ProtectedRoute } from "./ProtectedRoute";

export const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute path="/admin" component={AdminLayout} />
        <ProtectedRoute path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Redirect from="/" to="/auth" />
      </Switch>
    </BrowserRouter>
  );
};
